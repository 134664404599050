import React from "react";
import Gallery from 'react-grid-gallery';
import data from "./data";
let imagesDir = require.context('../../images/gallery', true);

class Explorer extends React.Component {
    constructor(props){
          super(props);

          this.state = {
              images: [],
              currentImage: 0
          };

          this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
          //this.deleteImage = this.deleteImage.bind(this);
      }

      UNSAFE_componentWillMount(){
          var qParam = this.props.location.search;
          var categoryName = qParam.slice(qParam.indexOf('=')+1)
          var catData = data[categoryName];
          this.setState({
          category:categoryName,
          images : catData.images,
          path:catData.path,
          colWidth:catData.colWidth
          })
        }

      onCurrentImageChange(index) {
          this.setState({ currentImage: index });
      }

       renderImages = () => {
          const { images } = this.state;

          let imgArray=[];
          images.map((imageSrc, index) => {
            let img_src = imagesDir(`./${this.state.path}/${imageSrc}`)
             imgArray.push({
                "src":img_src,
                "thumbnail":img_src,
                thumbnailWidth: 320,
                thumbnailHeight: 180
             })
            });

          return imgArray;
        }

      /*deleteImage() {
          if (window.confirm(`Are you sure you want to delete image number ${this.state.currentImage}?`)) {
              var images = this.state.images.slice();
              images.splice(this.state.currentImage, 1)
              this.setState({
                  images: images
              });
          }
      }*/

      render () {
          return (
              <div style={{
                  display: "block",
                  minHeight: "1px",
                  width: "100%",
                  border: "1px solid #ddd",
                  overflow: "auto"}}>
                  <div style={{
                       padding: "2px",
                       color: "#666"
                      }}>Current image: {this.state.currentImage}</div>
                  <Gallery
                      images={this.renderImages()}
                      enableLightbox={true}
                      enableImageSelection={false}
                      currentImageWillChange={this.onCurrentImageChange}

                      /*customControls={[
                          <button key="deleteImage" onClick={this.deleteImage}>Delete Image</button>
                      ]}*/
                  />
              </div>
          );
      }
}

export default Explorer;