export default  {
candle : {
    images: ['19.JPG','20.JPG','21.JPG','22.JPG','23.JPG','24.JPG','25.JPG','26.JPG','27.JPG','28.JPG','29.JPG','30.JPG','31.JPG','32.JPG','33.JPG','34.JPG','35.JPG','37.JPG','41.JPG','42.JPG','43.JPG','44.JPG','2.JPG','3.JPG','36.JPG','38.JPG','39.JPG','40.JPG','6.JPG','7.JPG','12.JPG','8.JPG','9.JPG','10.JPG','13.JPG','14.JPG','15.JPG','17.JPG','1.JPG','18.JPG','11.JPG','45.JPG'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"candle"
    },
jewellery : {
    images: ['7.jpeg','8.jpeg','9.jpeg','10.jpeg','4.JPG','6.JPG','1.JPG','2.JPG','3.JPG','5.JPG','5.JPG'],
    colWidth: ["4","4","4","4","4","4","4"],
    path:"jewellery"
    },
bottle : {
    images: ['3.jpeg','4.jpeg','5.jpeg','6.jpeg','7.JPG','8.JPG','2.JPG','1.JPG'],
    colWidth: ["4","4","4","4"],
    path:"bottle"
    },
pots : {
images: ['2.jpg','3.jpg','4.jpg','5.jpg','7.jpg','9.jpg','10.jpg','14.jpg','15.jpg','16.jpg','17.jpg','18.jpg','19.jpg','20.jpg','11.jpg','12.jpg'],
colWidth: ["4","4","4","4","4","4","4","4","4","4"],
    path:"pots"
    },
mandala : {
    images: ['1.JPG','3.JPG','4.JPG','5.JPG','6.JPG','7.JPG'],
    colWidth: ["4","4","4","4","4","4"],
    path:"mandala"
    },
mehndi : {
    images: ['2.jpg','3.jpg','4.jpg','7.jpg','8.jpg','9.jpg','10.jpg','12.jpg','13.jpg','14.jpg','15.jpg','21.jpg','22.jpg','23.jpg','25.jpg','26.jpg','27.jpg','28.jpg','29.jpg','30.jpg','32.jpg','34.jpg','35.jpg','36.jpg','37.jpg','38.jpg','39.jpg','40.jpg','43.jpg','44.jpg','45.jpg','46.jpg','47.jpg','48.jpg','52.jpg','55.jpg','56.jpg','58.jpg','18.jpg','19.jpg','20.jpg','53.jpg','60.jpg','1.jpg','5.jpg','6.jpg','11.jpg','16.jpg','17.jpg','31.jpg','33.jpg','41.jpg','42.jpg','50.jpg','51.jpg','49.jpg','54.jpg','57.jpg','59.jpg'],
    colWidth: ["4","3","3","3","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"mehndi"
    },
paintings : {
images: ['2.jpg','3.jpg','8.jpg','9.jpg','10.jpg','11.jpg','7.jpg','6.jpg','5.jpg','4.jpg','5.jpg'],    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"paintings"
    },
others : {
    images: ['17.jpg','18.jpg','19.jpg','20.jpg','22.jpg','23.jpg','24.jpg','25.jpg','26.jpg','27.jpg','28.jpg','29.jpg','7.jpg','11.jpg','13.jpg','16.jpg','5.jpg','14.jpg','15.jpg','21.jpg','3.jpg','2.jpg','10.jpg'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"others"
    },
karwachauth : {
    images: ['4.JPG','5.JPG','6.JPG','7.JPG','8.JPG','1.JPG','2.JPG','3.JPG'],
    colWidth: ["4","4","4","4","4","4","4","4"],
    path:"events/karwchauth"
    },
womensday : {
    images: ['4.JPG','5.JPG','6.JPG','8.JPG','9.JPG','10.JPG','1.JPG','2.JPG','3.JPG','7.JPG'],
    colWidth: ["4","4","4","4","4","4","4"],
    path:"events/womensday"
    }
};