export default  {
candle : {
    images: ['19.HEIC','20.HEIC','21.HEIC','22.HEIC','23.HEIC','24.HEIC','25.HEIC','26.HEIC','27.HEIC','28.HEIC','29.HEIC','30.HEIC','31.HEIC','32.HEIC','33.HEIC','34.HEIC','35.HEIC','36.HEIC','37.HEIC','38.HEIC','39.HEIC','40.HEIC','41.HEIC','42.HEIC','43.HEIC','44.HEIC','45.jpg','1.jpg','2.JPG','3.JPG','6.jpeg','7.jpeg','8.HEIC','9.HEIC','10.JPG','11.JPG','12.HEIC','13.HEIC','14.JPG','15.HEIC','16.HEIC','17.HEIC','18.HEIC'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"candle"
    },
jewellery : {
    images: ['1.HEIC','2.HEIC','3.HEIC','4.HEIC','5.HEIC','5.jpeg','6.jpg'],
    colWidth: ["4","4","4","4","4","4","4"],
    path:"jewellery"
    },
bottle : {
    images: ['2.HEIC','1.jpg', '3.HEIC','4.HEIC'],
    colWidth: ["4","4","4","4"],
    path:"bottle"
    },
pots : {
    images: ['2.HEIC','3.HEIC','4.HEIC','5.HEIC','7.HEIC','9.HEIC','10.HEIC','11.HEIC','12.HEIC','8.HEIC','13.jpeg','14.jpeg','15.jpeg','16.jpeg','17.jpeg','18.jpeg','19.jpeg','20.jpeg'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4"],
    path:"pots"
    },
mandala : {
    images: ['1.HEIC','3.HEIC','4.HEIC','5.HEIC','6.HEIC','7.HEIC'],
    colWidth: ["4","4","4","4","4","4"],
    path:"mandala"
    },
mehndi : {
    images: ['1.JPG','2.JPG','3.JPG','4.JPG','5.jpg','6.jpg','7.JPG','8.JPG','9.JPG','10.JPG','11.JPG','12.JPG','13.JPG','14.JPG','15.JPG','16.JPG','17.JPG','21.JPG','22.JPG','23.JPG','24.JPG','25.JPG','26.JPG','27.JPG','28.JPG','29.JPG','30.JPG','31.JPG','32.JPG','33.JPG','34.JPG','35.JPG','36.JPG','37.JPG','38.JPG','39.JPG','40.JPG','41.HEIC','42.HEIC','43.HEIC','44.HEIC','45.HEIC','46.HEIC','47.HEIC','48.HEIC','49.HEIC','50.HEIC','51.HEIC','52.HEIC','54.HEIC','55.HEIC','56.HEIC','57.HEIC','58.HEIC','18.JPG','19.JPG','20.JPG','53.HEIC'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"mehndi"
    },
paintings : {
    images: ['2.HEIC','3.HEIC','4.JPG','5.HEIC','5.jpg','6.HEIC','7.HEIC','8.HEIC','9.HEIC','10.HEIC','11.HEIC'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"paintings"
    },
others : {
    images: ['17.HEIC','18.HEIC','19.HEIC','20.HEIC','22.HEIC','23.HEIC','24.HEIC','25.HEIC','26.HEIC','27.HEIC','28.HEIC','29.HEIC','21.HEIC','2.jpeg','3.jpeg','5.HEIC','7.HEIC','10.HEIC','11.HEIC','13.HEIC','14.HEIC','15.HEIC','16.HEIC'],
    colWidth: ["4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4","4"],
    path:"others"
    },
karwachauth : {
    images: ['1.jpg','2.jpg','3.JPG','4.JPG','5.JPG','6.JPG','7.JPG','8.JPG'],
    colWidth: ["4","4","4","4","4","4","4","4"],
    path:"events/karwachauth"
    },
womensday : {
    images: ['1.jpeg','2.jpeg','3.jpeg','4.jpeg','5.jpeg','6.jpeg','7.jpeg','8.jpeg','9.jpeg','10.jpeg',],
    colWidth: ["4","4","4","4","4","4","4"],
    path:"events/womensday"
    }
};