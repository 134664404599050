export default [
{
    name:"Sri Upparapalli-Togi",
    imageName:'Sri.jpg',
    path:"testimonials",
    subject:"I wouldn’t have gotten any better artist",
    testimonial:"Mayuri is one gem of a person! She has amazing mehendi skills. She spent 7 hours putting my bridal mehendi and accommodated every single request of mine with a smile. I wouldn’t have gotten any better artist than her for my big day. I really loved the style, color, and design. Mayuri you are the best!!"
},
{
    name:"Vaishakhee Saraf",
    imageName:'Vaishakhee.jpg',
    path:"testimonials",
    subject:"A Must Recommended one.",
    testimonial:"Mayuri is a lovely person and her mehendi designs are very intricate and unique with reasonable rates. She drew mehendi for my 1st Sankranti event after marriage. A Must Recommended one."
},
{
    name:"Dalia Kubba",
    imageName:'Dalia.jpg',
    path:"testimonials",
    subject:"Much more henna parties to come!",
    testimonial:"Mayuri is such a professional. She is extremely kind and talented. Mayuri draws henna on your hand in a short period of time, with long lasting results. Much more henna parties to come! ❤️"
},
{
    name:"Kalyani Desai",
    imageName:'Kalyani.jpg',
    path:"testimonials",
    subject:"Quick and Professional",
    testimonial:"She did my baby shower mehndi. And she did excellent job. I am not fan of mehndi but I really like her designs. She is quick and professional in her work."
},
{
    name:"Taru Upadhyay Sharma",
    imageName:'Taru.jpg',
    path:"testimonials",
    subject:"Beautiful.. Really very Beautiful.",
    testimonial:"I got 4 candles from Mayuri. They all are very beautiful. Really very beautiful. Thanks Mayuri."
}
]