export default  [
    {
        name:"home",
        displayName:"Home",
        path:"home",
        icon:"home"
    },
    {
        name:"gallery",
        displayName:"Gallery",
        path:"gallery",
        icon:"images"
    },
    {
        name:"contactus",
        displayName:"Contact",
        path:"contactus",
        icon:"write"
    }
];