import React, { Component } from "react";
import { MDBMask, MDBView,MDBSmoothScroll, MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBCol, MDBRow } from "mdbreact";

let imagesDir = require.context('../../images/gallery', true);

  class TabsDefault extends Component {
    state = {
      activeItem: "candle"
    };

    UNSAFE_componentWillMount() {
      var categories = this.props.data;
          this.setState({
              categories : categories
          });
      }

    toggle = tab => e => {
      if (this.state.activeItem !== tab) {
        this.setState({
          activeItem: tab
        });
      }
    };

renderCategories = () => {
    const { categories } = this.state;
    return categories.map((category, index) => {
      return (
              <MDBNavItem>
                  <MDBNavLink link to="#" active={this.state.activeItem === category.name} onClick={this.toggle(category.name)} role="tab" >
                    {category.displayName}
                  </MDBNavLink>
              </MDBNavItem>
      );
    })
}

renderCategoriesContent = () => {
    const { categories } = this.state;
    return categories.map((category, index) => {
      let img_src = imagesDir(`./${category.path}/${category.imageName}`)
      return (
              <MDBTabPane tabId={category.name} role="tabpanel">
                <MDBRow className="mt-4">
                 <MDBCol md="4" class="offset-md-2">
                   <MDBView>
                    <a href={'./viewgallery?cat=' + category.name} className = "category-link" target="_blank" data-toggle="tooltip" title={'Explore ' + category.displayName + ' Gallery'}>
                       <img src={img_src} alt="" className="img-thumbnail" /><br/>
                        {'Explore ' + category.displayName + ' Gallery'}
                    </a>
                   </MDBView>
                 </MDBCol>
                 <MDBCol lg="5">
                    <div className="pad-top-bottom">
                        {category.description}
                    </div>
                 </MDBCol>
                 <MDBCol md="3"/>
                 </MDBRow>
                </MDBTabPane>
      );
    })
}

    render() {
      return (
        <MDBContainer>
        <h5><br/><strong>Explore Ashmayu's Gallery</strong></h5><br/>

        <MDBNav className="nav-tabs mt-2">
          {this.renderCategories()}
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} >
           {this.renderCategoriesContent()}
        </MDBTabContent>
      </MDBContainer>
    );
  }
}
export default TabsDefault;