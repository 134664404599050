import React from 'react'
import { Card, Image } from 'semantic-ui-react'
import dpic from './../images/misc/mayuri.jpg'

export default class About extends React.PureComponent {

render(){
return(
<div>
  <Card className="card_about">
    <Image src={dpic} wrapped ui={false}/>
    <Card.Content className="extra-content fore-color">
      <Card.Header className="fore-color">
       <h6 className="font-weight-bold mb-4">Mayuri</h6></Card.Header>

      <hr className="hr-box"/>
      <Card.Meta className="fore-color">
        <h6 className="font-style-italic">Artist by Passion, Accountant by Profession</h6>
      </Card.Meta>
      <hr className="hr-box"/>
      <Card.Description className="fore-color">
       Hello Everyone! Thanks for visiting !<br/>My name is <i>Mayuri</i> and I am the founder of <i>Ashmayu Art Studio</i>.
       <br/><br/>As a self-taught artist, I am very passionate about artwork. All work here is my own creation. I practice and lovingly
       enjoy various art forms for happiness with passion. I perform Mehndi(Henna), Candle Art, Paintings, Mandala, Crafts and more to define my art world.
       I have deep expertise, love and passion for Modern Art, Traditional Art, Contemporary Art, Decorative Art etc. <i>Ashmayu Art Studio</i> is an effort to do what I love and illuminate the surrounding with my work of art just like a ray of light. If you like my work, please share with your family and friends and if you have suggestions / feedback, please <u><a className="category-link" href="/ContactUs">contact us</a></u>.</Card.Description>
    </Card.Content>
  </Card>
  </div>
  )
  }
}