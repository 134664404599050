import React, { Component } from "react";
import { Icon } from 'semantic-ui-react'
import data from './data';

export default class SocialMediaLinks extends Component {

state = {}

UNSAFE_componentWillMount(){
    var items = data;
    this.setState({
        items : items
    })
  }

renderSocialMediaLinks = () => {
 const { items } = this.state;

    return items.map((item, index) => {
      return (
       <a href= {item.link} target="_blank" rel="noopener noreferrer" key={index} data-toggle="tooltip" title={"Ashmayu's "  + item.displayName}>
            <Icon name={item.iconName}  color={item.color}  className={this.props.className ? this.props.className : "media-links-icon"} link circular/>
       </a>
    )}
)}

render() {
    return (
          this.renderSocialMediaLinks()
    );
  }
}