import React from "react";
import { Card, Icon } from 'semantic-ui-react'
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks'
import LogoImage from '../../components/LogoImage/LogoImage'

const ContactUs = props => {
  return (
   <div>
       <Card id="card-contact1" key="card-contact1" className= {"card_about"}>
           <br/><LogoImage isDarkTheme={props.isDarkTheme} full ={true} wrapped ui={false} className={"pad-bottom-10"}/><br/><br/>
           <Card.Content extra className="extra-content"><br/>
              <p><Icon name='map marker alternate'/>
              Sterling, VA 20166, USA</p><hr className="hr-box"/><br/>
             <p> We would love to hear from you.! If you have suggestions or feedback, please write to us. We are very responsive over emails.</p>
             <br/>
              <Icon name='mail'/>
                  <a href= "mailto:Ashmayu.Net@gmail.com?subject=Mail from Ashmayu.Net" className="fore-color-2">
                      Ashmayu.Net@gmail.com
                  </a>
             <br/>
             <hr className="hr-box"/><br/>
             <p>We are available and active on all major social media platforms, for latest updates please follow us on the links below</p><br/>
                <SocialMediaLinks isDarkTheme={props.isDarkTheme} className="media-links-icon"/>
             <p className="dark-grey-text mt-4">
             </p>
           </Card.Content>
         </Card>
     </div>
  );
}

export default ContactUs;