import React, {Component} from 'react';
import balloon_b from '../../icons/Ashmayu_Balloon_B.png';
import balloon_w from '../../icons/Ashmayu_Balloon_W.png';
import full_b from '../../icons/Ashmayu_Full_B.png';
import full_w from '../../icons/Ashmayu_Full_W.png';
import original_b from '../../icons/Ashmayu_B.png';
import original_w from '../../icons/Ashmayu_W.png';

export default class LogoImage extends Component {
   state = {}

render() {
    const isDarkTheme = localStorage.getItem('theme') === "Night";
    const actClassName = this.props.className;

    let src;
     if(this.props.balloon){
         src = isDarkTheme ? balloon_b : balloon_w;
        } else if (this.props.full){
            src = isDarkTheme ? full_b : full_w;
        } else {
            src = isDarkTheme ? original_b : original_w;
        }

    return (
      <img
          src = {src}
          className={actClassName}
          alt=""
     />
    )
  }
}