import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks'
import smds from './../../images/misc/SMDA_LOGO.png'

class FooterPage extends React.Component {

renderQuickLinks = () => {
    return this.props.data.map((item, index) => {
      return (<div key={index}>
        <a className="color-white" href={'/viewgallery?cat='+item.name} data-toggle="tooltip" title={'View ' + item.displayName + ' Gallery'}>
        {item.displayName}</a>
    </div>)}
)}

render(){
  return (
    <MDBFooter color="black" className="font-small pt-4 mt-4 footer-color">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol >
              <h5 className="title">Quick Gallery Links</h5>
                  <li className="list-unstyled">
                      {this.renderQuickLinks()}
                   </li>
            </MDBCol>
            <MDBCol>
              <h5 className="title">More Links</h5>
               <li className="list-unstyled">
                   <a className="color-white" href="/About">About</a>
               </li>
              <li className="list-unstyled">
                    <a className="color-white" href="/Testimonials">Testimonials</a>
              </li>
              <li className="list-unstyled">
                  <a className="color-white" href="/Videos">Videos</a>
              </li>
              <li className="list-unstyled">
                <a className="color-white" href="/Events">Events</a>
              </li>
              <li className="list-unstyled">
                 <a className="color-white" href="/ContactUs">Contact Us</a>
              </li>
            </MDBCol>
            <MDBCol>
                <h5 className="title">Follow Us</h5>
                 <li className="list-unstyled">
                    <SocialMediaLinks className={"media-links-icon-invert"}/>
                 </li>
        </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br/>
      <div className="footer-copyright text-center py-3 footer-color-deep">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright :&nbsp;
          <a className="color-white" href="https://www.ashmayu.net">www.Ashmayu.net</a>&nbsp;<br/>Powered By :&nbsp;
          <a className="color-white" target="_blank" rel="noopener noreferrer" href="https://saurabhmadan.wixsite.com/portfolio/about">
            <img src={smds} className="footer-logo" alt=""/></a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
  }
}

export default FooterPage;