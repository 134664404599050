import React from "react";
import {  MDBRow, MDBCol, MDBMask, MDBView } from "mdbreact";
import logo_w from '../../icons/Ashmayu_Full_W.png'
import logo_b from '../../icons/Ashmayu_Full_B.png'

const BlogPage = (props) => {
  return (
        <MDBRow>
          <MDBCol md={"4"}>
            <MDBView hover waves>
              <br/><br/>
              <img
                src={props.isDarkTheme ? logo_b : logo_w}
                alt=""
                className = "logo-blog"
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol md={"7"} sm={"1"} className="col-align-left">
            <h5>
              <br/><strong>Cosmos Of "<i>The Eternal Art</i>" Welcomes You.</strong>
            </h5>
            <p>
               Welcome to <i>Ashmayu Art Studio</i>.! The word Ashmayu hails from Sanskrit origin which means <i>Ray Of Light</i>. Ashmayu's mission is to illuminate the surroundings with Artistic Skills, Innovation, Passion and Lots of love under the umbrella of different Art forms. Ashmayu's effort is to create and share exclusive gallery of fine art where each crafted piece has a unique signature and outlook. Each piece of art at Ashmayu is 100% hand-crafted and original. We craft from Metal, Wood, Wax, Henna, Clay, Paint, Stone, Wood, Paper, Clothes and many many more.
            </p>
          </MDBCol>
        </MDBRow>
  );
}

export default BlogPage;