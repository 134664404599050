import React, {Component} from 'react';
import {Menu,Image,Icon,Dropdown} from 'semantic-ui-react'
import data from './data'
import SideNavBar from './SideNavBar'
import balloon_b from '../../icons/Ashmayu_Balloon_B.png';
import balloon_w from '../../icons/Ashmayu_Balloon_W.png';

const friendOptions = [
  {
    key: 'Day',
    text: 'Blazing Day',
    value: 'Day',
    image: { avatar: true, src: balloon_w }
  },
  {
    key: 'Night',
    text: 'Gloomy Night',
    value: 'Night',
    image: { avatar: true, src: balloon_b }
  }
]

const trigger = isDarkTheme => (
  <span>
    <Image className="no-border" avatar src={isDarkTheme ? balloon_b : balloon_w } /><br/>
  </span>
)

export default class Header extends Component {

   state = {}

  handleItemClick = (e, { name }) =>{
  this.setState({ activeItem: name })
  window.location.href = '/'+name;
}

getDropdownStatus = () => {
      return localStorage.getItem('theme') === "Day" ?  "Night" : "Day";
  };

 state = {
    switch1: this.getDropdownStatus()
  }

  handleDropdownChange = (e, { name,value} ) => {
    this.setState({
      switch1:this.getDropdownStatus()
    })
    this.props.action();
  }

UNSAFE_componentWillMount(){
    var items = data;
    this.setState({
        items : items
    })
  }

renderItems = () => {
    const { items } = this.state;

    return items.map((item, index) => {
      return (
       <Menu.Item
               name={item.name}
               key={item.name}
               active={this.state.activeItem === item.name}
               onClick={this.handleItemClick}
        >
            <Icon name= {item.icon} className="header-icon" />
               {item.displayName}
        </Menu.Item>
      );
    })
}




render() {
    return (
    <div>
    <div className="menu-container">
    <SideNavBar/>
      <Menu size='mini'   icon='labeled' className="menu-class" inverted={this.props.isDarkTheme}>
            {this.renderItems()}
          <div>
          <Dropdown
               trigger={trigger(this.props.isDarkTheme)}
              floating
              button
              direction="left"
              className='icon theme-selector'
              value={this.state.switch1}
            >
              <Dropdown.Menu className="menu-theme-selector" >
                <Dropdown.Header content='Choose your web experience' className="header-theme-selector" />
                <Dropdown.Divider />
                {friendOptions.map((option) => (
                  <Dropdown.Item disabled={this.getDropdownStatus() !== option.value} onClick={this.handleDropdownChange} icon={option.iconname} key={option.value}  {...option} className="header-theme-selector"/>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
      </Menu>
      </div><br/>
      </div>
    )
  }
}