import React, { Component } from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, MDBCardImage} from "mdbreact";
let imagesDir = require.context('../../images/gallery', true);

export default class MultiCarouselPage extends Component {

  UNSAFE_componentWillMount() {
  var categories = this.props.data;
      this.setState({
          categories : categories
      });
        this.setState({ isMobile: window.innerWidth < 580 });
  }

renderImages = (from,to) => {
    const { categories } = this.state;

    return categories.map((category, index) => {
    if(index >= from &&  index <= to){
      let img_src = imagesDir(`./${category.path}/${category.imageName}`)
      return (
           <MDBCol md="4" className="float-left" key={index}>
              <a href={'./viewgallery?cat=' + category.name} data-toggle="tooltip" className="category-link" title={'Explore'}>
                <MDBCardImage className="img-fluid popular-image" src={img_src} />
               </a>
            </MDBCol>
      );
    }})
}

render(){
let totalItems = this.state.categories.length +1;
let itemsPerSlide = 3;
let len = totalItems / itemsPerSlide;
  return (
    <MDBContainer>
      <MDBCarousel activeItem={1} length={len} slide={true}>
        <MDBCarouselInner>
          <MDBRow>
            <MDBCarouselItem itemId={1}>
                {this.renderImages(0,2)}
             </MDBCarouselItem>
              <MDBCarouselItem itemId={2}>
                   {this.renderImages(3,5)}
               </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    {this.renderImages(6,8)}
                 </MDBCarouselItem>
            </MDBRow>
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  );
  }
}