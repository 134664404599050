export default  [
    {
        name:"mandala",
        imageName: "mandala.JPG",
        path:"popular",
    },
    {
        name:"candle",
        imageName: "candle.JPG",
        path:"popular",
    },
    {
        name:"bottle",
        imageName: "bottle.JPG",
        path:"popular",
    },
    {
        name:"mehndi",
        imageName: "mehndi_2.jpg",
        path:"popular",
    },
    {
        name:"paintings",
        imageName: "paintings.jpg",
        path:"popular",
    },
    {
        name:"jewellery",
        imageName: "jewellery.JPG",
        path:"popular",
    },
    {
        name:"pots",
        imageName: "pots.jpg",
        path:"popular",
    },
    {
        name:"others",
        imageName: "others.jpg",
        path:"popular",
    },
    {
        name:"mehndi",
        imageName: "mehndi.jpg",
        path:"popular",
    }
];