export default  [
    {
        name:"candle",
        displayName:"Candle-Art",
        imageName: "candle_2.JPG",
        path:"categories",
        description:"Hand crafted colorful Mehndi / Acrylic Colors to add beauty on Candles. They are uniquely Designed and can be customized to add personalized touch."
    },
    {
        name:"mandala",
        displayName:"Mandala-Art",
        imageName: "mandala.JPG",
        path:"categories",
        description:"This unique art is really beautiful and it can take a form of painting on a wall or scroll, various kitchen wares, pots etc."
    },
    {
        name:"mehndi",
        displayName:"Mehndi-Art",
        imageName: "mehndi.JPG",
        path:"categories",
        description:"Mehndi designs are created on a person's body using a Henna. In some countries Mehndi is also part of rituals which are practiced on occasions like Wedding Ceremony, Baby-Shower, Festivals etc."
    },
    {
        name:"jewellery",
        displayName:"Jewelleries",
        imageName: "jewellery.JPG",
        path:"categories",
        description:"Hand crafted flower/floral jewelry for Mehndi Day, Baby Shower, Janmashtami etc. Each jewelry is uniquely and originally designed."
    },
    {
        name:"paintings",
        displayName:"Paintings",
        imageName: "paintings.JPG",
        path:"categories",
        description:"Please check out our paintings which includes modern and traditional art."
    },
    {
        name:"bottle",
        displayName:"Bottle-Art",
        imageName: "bottle.jpeg",
        path:"categories",
        description:"Beverage bottles decorated with heavy body paint and materials produce textured look which is unique and very beautiful."
    },
    {
        name:"pots",
        displayName:"Pots",
        imageName: "pots.JPG",
        path:"categories",
        description:"Various types of pots and vases painted and decorated using different mediums and Techniques."
    },
    {
        name:"others",
        displayName:"Miscellaneous",
        imageName: "others.JPG",
        path:"categories",
        description:"Hand decorated and crafted objects like Mehndi / Haldi Rasam Thal, Flower Rangoli, Best Out of Waste Projects, Diya's and many more."
    }
];