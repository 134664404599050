import React, {Component} from 'react';
import Carousel from '../components/Carousel/Carousel'
import BlogPage from '../components/Blog/BlogPage'
import MultiCarouselPage from '../components/MultiCarouselPage/MultiCarouselPage'
import data from '../components/MultiCarouselPage/data'

export default class Home extends Component {

render(){
return (
          <div>
             <Carousel/>
             <br/>
               <hr className="hr-box-3"/>

            <BlogPage isDarkTheme = {this.props.isDarkTheme}/>
              <hr className="hr-box-3"/>
              <h5>
                <br/><strong>Our Popular Picks</strong>
              </h5><br/>
              <MultiCarouselPage data = {data}/>
              <hr className="hr-box-3"/>
        </div>
      )
  }
}