import React, {Component} from 'react';
import {Route, Switch, Redirect } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import ContactUs from './components/ContactUs/ContactUs'
import Testimonials from './components/Testimonials/Testimonials'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import LightboxPage from './components/Gallery/Gallery'
import GridGallery from './components/Explorer/GridGallery'
import Categories from './components/Categories/Categories'
import Categories3 from './components/Categories/Categories3'
import categoriesData from './components/Categories/data_categories'
import eventsData from './components/Categories/data_events'
import Embed from './components/Embed/Embed'
import Helmet from 'react-helmet'

class App extends Component {

state={
    theme:"Day"
}

handleThemeChange=()=>{
    localStorage.setItem('theme', this.getThemeName()==="Day" ? "Night" : "Day");
    this.setState({
        theme:this.getThemeName()
     });
};

UNSAFE_componentWillMount(){
 if(!localStorage.getItem('theme')){
   localStorage.setItem('theme', "Day");
 }
}

getThemeName = () => {
    return localStorage.getItem('theme') ? localStorage.getItem('theme') : "Day";
};

isDarkTheme = () => {
    return localStorage.getItem('theme') === "Night";
};

render(){
const idt=this.isDarkTheme();
  return (
    <div className="App app-container">
        <div>
           <Header action={this.handleThemeChange} isDarkTheme ={idt} history={this.props.history} {...this.props}/>
             <Helmet>
                 {<link rel="stylesheet" type="text/css" href="./font-awesome/css/font-awesome.min.css"/>}
                { idt ? <link rel="stylesheet" href="./App_Dark.css" /> : <link rel="stylesheet" href="./App.css" /> }
            </Helmet>
               <Switch>
                    <Route exact path="/" render={(props) => <Home action={this.handleThemeChange} isDarkTheme ={idt} history={this.props.history} {...props} /> } />
                    <Route exact path="/about" render={(props) => <About isDarkTheme={idt} {...props} /> } />
                    <Route exact path="/contactus"  render={(props) => <ContactUs isDarkTheme={idt} {...props} /> } />
                    <Route exact path="/testimonials" render={(props) => <Testimonials {...props} /> } />
                    <Route exact path="/events" render={(props) => <Categories  data={eventsData} {...props} /> } />
                    <Route exact path="/gallery" render={(props) => <Categories3 data={categoriesData} {...props} /> } />
                    <Route exact path="/viewgallery" render={(props) => <LightboxPage {...props} /> } />
                    <Route exact path="/explorer" render={(props) => <GridGallery {...props} /> } />
                    <Route exact path="/videos" render={(props) => <Embed {...props} /> } />

                    <Redirect to="/" />
                </Switch>
             <Footer data={categoriesData}  />
        </div>
    </div>
  );
  }
}

export default App;