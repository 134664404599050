import React, {Component} from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from
"mdbreact";
import data from "./data";
var imagesDir = require.context('../../images', true);


class CarouselPage extends Component {

UNSAFE_componentWillMount (){
    var images = data.images;
    this.setState({
    images : images,
    path:data.path
    })
  }

renderImages = () => {
    const { images } = this.state;

    return images.map((imageSrc, index) => {
      let img_src = imagesDir(`./${this.state.path}/${imageSrc}`)

      return (
        <MDBCarouselItem itemId={index} key={index}>
         <MDBView waves>
           <img
             className="d-block w-100"
             src={img_src}
             alt="Slide"
           />
         </MDBView>
       </MDBCarouselItem>
      );
    })
  }

render(){
 return (
     <MDBContainer>
       <MDBCarousel
         slide
         activeItem={1}
         length={this.state.images.length-1}
         showControls={true}
         showIndicators={true}
         className="z-depth-1"
         interval={2000}
         onHoverStop={false}>
         <MDBCarouselInner>
            {this.renderImages()}
         </MDBCarouselInner>
       </MDBCarousel>
     </MDBContainer>
   );
 }
}
export default CarouselPage;