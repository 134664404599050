import React, { Component } from "react";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";
import throttle from 'lodash.throttle';
let imagesDir = require.context('../../images/gallery', true);

export default class Categories extends Component {
 state = {
    isMobile: false
  }

throttledHandleWindowResize = () => {
    return throttle(() => {
      this.setState({ isMobile: window.innerWidth < 580 })
    }, 200);
  }

  UNSAFE_componentWillMount() {
  var categories = this.props.data;
      this.setState({
          categories : categories
      });
        this.setState({ isMobile: window.innerWidth < 580 });
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledHandleWindowResize);
  }

  UNSAFE_componentWillUnMount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

handleSelectCategory = (category) =>{
  window.location.href = category;
}

renderImages = () => {
    const { categories } = this.state;

    return categories.map((category, index) => {
      let img_src = imagesDir(`./${category.path}/${category.imageName}`)
      return (
        <div key={category.name} className="category-container">
            <a href={'./viewgallery?cat=' + category.name} data-toggle="tooltip" className="category-link" title={'Explore ' + category.displayName + ' Gallery'}>
                <img src={img_src} className="categories-image" alt=""/>
                <br/>
                <hr className="hr-box-2"/>
                {category.displayName}</a>
                <hr className="hr-box-2"/>
                <p className='desc-box-2'>{category.description}</p><br/>
        </div>
      );
    })
}

render() {
const { isMobile } = this.state;
let noOfSlides = isMobile ? 1 : 4;
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: this.state.categories.length < noOfSlides ? this.state.categories.length : noOfSlides,
      slidesToScroll: !isMobile ? 1 : 1,
      autoplay: !isMobile,
      // rows: this.state.categories.length < noOfSlides ? noOfSlides : 1,
      rows: isMobile ? this.state.categories.length : 1,
      speed: 4000,
      autoplaySpeed: 10,
     // cssEase: "linear",
      swipeToSlide: !isMobile
  };
    return (
      <div>
      <h5><br/><strong>Explore Ashmayu's Gallery</strong></h5><br/>
        <Slider {...settings}>
           {this.renderImages()}
       </Slider>
       <br/>
      </div>
    );
  }
}