import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import data from './data'
var imagesDir = require.context('../../images', true);

class TestimonialsPage extends React.Component {

UNSAFE_componentWillMount(){
    var testimonials = data;
    this.setState({
        testimonials : testimonials
    })
  }

renderImages = () => {
    const { testimonials } = this.state;

    return testimonials.map((testimonial, index) => {
      let img_src = imagesDir(`./${testimonial.path}/${testimonial.imageName}`)
      return (
        <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
            <MDBCard testimonial className="card-box">
                <img
                  src={img_src}
                  alt=""
                  className="rounded-circle img-fluid img-shrink"
                />
              <MDBCardBody>
                <h7 className="font-weight-bold mb-4">{testimonial.name}</h7>
                <hr className="hr-box"/>
                <h7 className="font-style-italic">
                  {testimonial.subject}
                </h7>
                <hr className="hr-box"/>
                <p className="dark-grey-text mt-4">
                  {testimonial.testimonial}
                </p>
              </MDBCardBody>
            </MDBCard>
            <br/>
          </MDBCol>
      );
    })
  }

render(){
  return (
    <MDBContainer>
      <section className="text-center my-5">
      <h5><br/><strong>Testimonials</strong></h5><br/>
        <p className="dark-grey-text w-responsive mx-auto mb-5">
          We are pleased to share these wonderful testimonials from our friends alike. These wonderful stories help us strive forward with our art work.
        </p>
        <MDBRow>
          {this.renderImages()}
        </MDBRow>
      </section>
    </MDBContainer>
  );
 }
}

export default TestimonialsPage;