import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Lightbox from "react-image-lightbox";
import data from "./data";
import "./Lightbox.css";
let imagesDir = require.context('../../images/gallery', true);

class LightboxPage extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [
    ],
    colWidth: []
  }

  UNSAFE_componentWillMount(){
    var qParam = this.props.location.search;
    var categoryName = qParam.slice(qParam.indexOf('=')+1)
    var catData = data[categoryName];
    this.setState({
    category:categoryName,
    images : catData.images,
    path:catData.path,
    colWidth:catData.colWidth
    })
  }

  renderImages = () => {
    let photoIndex = -1;
    const { images } = this.state;

    return images.map((imageSrc, index) => {
      photoIndex++;
      const privateKey = photoIndex;
      let img_src = imagesDir(`./${this.state.path}/${imageSrc}`)

      return (
        <MDBCol md="2" key={photoIndex} className="no-pad-right">
          <figure>
            <img
              src={img_src}
              className="cursor-zoom-in img-fluid z-depth-1 img-thumbnail"
              alt="Gallery"
              title='Click to zoom in'
              draggable="true"
              onClick={() =>
                this.setState({ photoIndex: privateKey, isOpen: true })
              }
            />
          </figure>
        </MDBCol>
      );
    })
  }

  render() {
    const { photoIndex, isOpen, images } = this.state;

    return (
      <MDBContainer className="mt-5 p-3" >
        <div className="mdb-lightbox p-3">
          <MDBRow>
            {this.renderImages()}
          </MDBRow>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc= {imagesDir(`./${this.state.path}/${images[photoIndex]}`)}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            imageTitle={photoIndex + 1 + "/" + images.length}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
        <MDBCol md="12" className="text-center py-4">
         
        </MDBCol>
      </MDBContainer >
    );
  }
}

export default LightboxPage;