export default  [
    {
        name:"karwachauth",
        displayName:"Karwa-chauth Celebration",
        imageName: "karwachauth.JPG",
        path:"categories",
        description:"Ashmayu had an opportunity to showcase Henna skills in many Karwachauth parties. Please follow the gallery for a glimpse."
    },
    {
        name:"womensday",
        displayName:"Women's Day Celebration",
        imageName: "womensday.JPG",
        path:"categories",
        description:"Ashmayu's Womens's day celebration event was a big hit, we did free Mehndi service for Ladies to make them feel special on the beautiful day."
    }
];