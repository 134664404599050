export default  [
    {
        name:"facebook",
        displayName:"Facebook",
        iconName: "facebook f",
        iconColor: "blue",
        iconSize: "large",
        link:"https://www.facebook.com/Ashmayu.net/"
    },
    {
        name:"messenger",
        displayName:"Messenger",
        iconName: "facebook messenger",
        iconColor: "blue",
        iconSize: "large",
        link:"https://m.me/Ashmayu.net"
    },
    {
        name:"instagram",
        displayName:"Instagram",
        iconName: "instagram",
        iconColor: "orange",
        iconSize: "large",
        link:"https://www.instagram.com/ashmayu_net/"
    },
    {
        name:"youtube",
        displayName:"Youtube",
        iconName: "youtube",
        iconColor: "red",
        iconSize: "large",
        link:"https://www.youtube.com/channel/UCwfb1CSrCPpVJrpCSDMRTCw"
    },
    {
        name:"pinterest",
        displayName:"Pinterest",
        iconName: "pinterest",
        iconColor: "red",
        iconSize: "large",
        link:"https://in.pinterest.com/ashmayu_net/"
    },
    {
        name:"twitter",
        displayName:"Twitter",
        iconName: "twitter",
        iconColor: "red",
        iconSize: "large",
        link:"https://twitter.com/ashmayu_net"
    }
];