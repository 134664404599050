import React from 'react'
const EmbedExampleYouTube = () => {
  return (
  <div className="embed-container">
     <h5><br/><strong>Ashmayu's Videos</strong></h5><br/>
     <div className="embed-responsive embed-responsive-16by9 video-class">
        <iframe title="Ashmayu's Video" className="embed-responsive-item" src="https://www.youtube.com/embed/-oWB6gs08NU" allowFullScreen></iframe>
     </div>
     <div className="embed-responsive embed-responsive-16by9 video-class">
        <iframe title="Ashmayu's Video" className="embed-responsive-item" src="https://www.youtube.com/embed/JEzbv7QT-34" allowFullScreen></iframe>
     </div>
     <div className="embed-responsive embed-responsive-16by9 video-class">
        <iframe title="Ashmayu's Video" className="embed-responsive-item" src="https://www.youtube.com/embed/LapQKa2b4IY" allowFullScreen></iframe>
     </div>
   </div>
  )
}

export default EmbedExampleYouTube