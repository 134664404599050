import React, { Component } from 'react';
import BurgerMenu from 'react-burger-menu';
import LogoImage from '../../components/LogoImage/LogoImage'
import {Icon} from 'semantic-ui-react'

const menus = {
  slide: {buttonText: 'Slide', items: 1}
};

class MenuWrap extends Component {
  constructor (props) {
    super(props);
    this.state = {
      hidden: false
    };
  }

  componentDidUpdate(nextProps) {
    const sideChanged = this.props.children.props.right !== nextProps.children.props.right;

    if (sideChanged) {
      this.setState({hidden : true});

      setTimeout(() => {
        this.show();
      }, this.props.wait);
    }
  }

  show() {
    this.setState({hidden : false});
  }

  render() {
    let style;

    if (this.state.hidden) {
      style = {display: 'none'};
    }

    return (
      <div style={style} className={this.props.side}>
        {this.props.children}
      </div>
    );
  }
}

export default class SideNavBar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      currentMenu: 'slide',
      side: 'left'
    };
  }

  changeMenu(menu) {
    this.setState({currentMenu: menu});
  }

  changeSide(side) {
    this.setState({side});
  }

  getItems() {
    let items;

    switch (menus[this.state.currentMenu].items) {
      default:
        items = [
          <h2 key="0"><LogoImage  className="fa fa-fw fa-3x" /><span><br/><br/></span></h2>,
          <a key="1" href="/About"><i className="fa fa-fw" />
            <Icon name='user circle outline' size='large'/>
            <span className="menu-span">About</span>
          </a>,
          <a key="2" href="/Testimonials"><i className="fa fa-fw"/>
            <Icon name='certificate' size='large'/>
            <span className="menu-span">Testimonials</span>
          </a>,
          <a key="3" href="/Videos"><i className="fa fa-fw" />
            <Icon name='video' size='large'/>
          <span className="menu-span">Videos</span></a>,
          <a key="4" href="Events"><i className="fa fa-fw" />
            <Icon name='handshake' size='large'/>
            <span className="menu-span">Events</span>
          </a>,
          <a key="4" href= "mailto:Ashmayu.Net@gmail.com?subject=Mail from Ashmayu.Net"><i className="fa fa-fw" />
              <Icon name='write' size='large'/>
              <span className="menu-span">Write to us</span>
            </a>

        ];
    }
    return items;
  }

  getMenu() {
    const Menu = BurgerMenu[this.state.currentMenu];

    return (
      <MenuWrap wait={20} side={this.state.side}>
        <Menu id={this.state.currentMenu} pageWrapId={'page-wrap'} outerContainerId={'outer-container'} right={this.state.side === 'right'}>
          {this.getItems()}
        </Menu>
      </MenuWrap>
    );
  }

  render() {
    return (
      <div id="outer-container" style={{height: '100%'}}>
        {this.getMenu()}

      </div>
    );
  }
}

